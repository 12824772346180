.participant_container_default {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.participant_container_default3 {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.participant_container_layout4 {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.participant_container_layout5 {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.for_p_container_default{
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 50%;
}
.for_p_container_layout1{
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 56%;
}
.for_p_container_layout2{
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 62%;
}
.for_p_container_layout3{
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 100%;
}
.for_p_container_layout4 {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 100%;
}
.for_p_container_layout5 {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 100%;
}

.participant_control_container {
    text-align: center;
    padding: 30px 20px;
    margin-top: 15px;
    border-radius: 6px;
    width: 85%;
    background-color: rgba(46, 53, 62, 0.42);
}

.participant_control_container>h2 {
    font-size: 30px;
    font-weight: 500;
    margin: 0;
}

.participant_control_container>p {
    margin: 20px 0;
    font-size: 16px;
}

.participant_control {
    display: flex;
    flex-direction: row;
    width: 85%;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 5px;
    border-radius: 25px;
    background-color: #18232B;
    font-size: 12px;
}

.participant_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    background-color: #18232B;
    color: #f9f9f9;
    background-position: center;
    background-size: 25px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.participant_icon.lightning {
    background-image: url('../../assets/lightning_inactive.png');
}

.participant_icon.lightning.active {
    background-image: url('../../assets/lightning_active.png');
}

.participant_icon.smile {
    background-image: url('../../assets/smile_inactive.png');
}

.participant_icon.smile.active {
    background-image: url('../../assets/smile_active.png');
}

.participant_icon.phone {
    background-image: url('../../assets/phone_inactive.png');
}

.participant_icon.phone.active {
    background-image: url('../../assets/phone_active.png');
}

.participant_icon.home {
    background-image: url('../../assets/home_inactive.png');
}

.participant_icon.home.active {
    background-image: url('../../assets/home_active.png');
}

.active {
    border: 1px solid #f9f9f9;
    background-color: #f9f9f9;
    color: #282c34;
}
.default {
    width: 97%;
    border-radius: 7px;
    object-fit: cover;
    max-height: 60vh;
    min-height: 60vh;
    min-width: 40vw;
    max-width: 40vw;
    margin: 3px;
}
.default3 {
    width: 97%;
    border-radius: 7px;
    object-fit: cover;
    max-height: 40vh;
    min-height: 40vh;
    min-width: 32vw;
    max-width: 32vw;
    margin: 3px;
}
.layout1 {
    width: 97%;
    border-radius: 7px;
    object-fit: cover;
    max-height: 35vh;
    min-height: 35vh;
    min-width: 27vw;
    max-width: 27vw;
    margin: 3px;
}
.layout2 {
    width: 97%;
    border-radius: 7px;
    object-fit: cover;
    max-height: 40vh;
    min-height: 40vh;
    min-width: 30vw;
    max-width: 30vw;
    margin: 3px;
}
.layout3 {
    width: 97%;
    border-radius: 7px;
    object-fit: cover;
    max-height: 48vh;
    min-height: 48vh;
    min-width: 42vw;
    max-width: 42vw;
    margin: 3px;
}
.layout4 {
    width: 97%;
    border-radius: 7px;
    object-fit: cover;
    max-height: 72vh;
    min-height: 72vh;
    min-width: 50vw;
    max-width: 50vw;
    margin: 3px;
}
.layout5 {
    width: 97%;
    border-radius: 7px;
    object-fit: cover;
    max-height: 100vh;
    min-height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    margin: 3px;
}