

video {
    width: 97%;
    border-radius: 7px;
    object-fit: cover;
    /*object-fit: cover;*/
}
.video-container {
    position: relative;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.control-video-block {
    position: absolute;
    /*height: 30px;*/
    bottom: 5px;
    right: 5px;
    font-weight: bold;
    font-size: 17px;
    display: flex;
    border-radius: 0 5px 0 0;
}

.participant-camera {
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.participant-camera img {
    width: 20px;
    height: 20px;
}

.participant-mic {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.participant-mic img {
    width: 20px;
    height: 20px;
}

.active-speaker {
    border: 1px solid #ffe484;
}
.participant-name {
    background-color: #5215FEFF;
    padding: 5px 12px;
    border-radius: 15px;
    text-transform: capitalize;
}