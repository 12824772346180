.participant_list_container {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding: 5px;
    border-radius: 10px;
    margin: 5px;
}

.participant {
    margin: 3px;
}

.participant_control_input-container {
    display: flex;
}

.participant_control_input {
    background-color: #282c34;
    width: 100%;
    height: 6vh;
    padding: 0 14px;
    border: 1px solid #282c34;
    border-radius: 5px;
    color: #f9f9f9;
    font-size: 16px;
    font-weight: bold;
    outline: none;
    letter-spacing: 0.2em;
}

.participant_control_button {
    width: 100%;
    height: 6vh;
    margin: 20px 0 0 0;
    border-radius: 5px;
    border: 1px solid #f9f9f9;
    box-shadow: 3px 10px 18px 7px rgba(0, 0, 0, 0.43);
    color: #000;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}
.ok_icon {
    width: 37px;
    margin-left: -42px;
    height: 30px;
    display: flex;
    justify-self: center;
    align-self: center;
    border-radius: 30px;
    color: #f9f9f9;
    background-position: center;
    background-size: 25px;
    background-repeat: no-repeat;
    background-image: url('../../assets/okey_icon.png');
}
