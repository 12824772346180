.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.qr-container {
  position:absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(67, 31, 61);
}
.App-container {
  /*background-color: #191d25;*/
  background-image: url('assets/background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Content-container {
  flex-direction: column;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
#key-code {
  font-size: 33px;
  color: #fff;
}
.sceenic-logo {
  background-image: url('assets/xumo-logo.png');
  background-repeat: no-repeat;
  width: 80px;
  z-index: 1;
  height: 40px;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 10px;
  right: 5%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
